import { JsonObject, JsonProperty } from 'json2typescript'
import Model from './Model'

@JsonObject('GrantedCoverItem')
export default class GrantedCoverItem extends Model {
  @JsonProperty('title', String)
  title: string

  @JsonProperty('value', String)
  value: string

  constructor(
    id = 0,
    title = '',
    value = '',
  ) {
    super(id)
    this.title = title
    this.value = value
  }
}
