import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from './DefinedList'
import PersonalDetails from './PersonalDetails'
import Address from './Address'

@JsonObject('AdditionalPolicyholder')
export default class AdditionalPolicyholder extends PersonalDetails {
  @JsonProperty('maritalStatus', DefinedListDetail, true)
  maritalStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('relationshipToPolicyholder', DefinedListDetail, true)
  relationshipToPolicyholder: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('employmentStatus', DefinedListDetail, true)
  employmentStatus: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('occupation', DefinedListDetail, true)
  occupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('businessType', DefinedListDetail, true)
  businessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('studentType', DefinedListDetail, true)
  studentType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('hasSecondaryEmployment', Boolean, true)
  hasSecondaryEmployment: boolean | null = null

  @JsonProperty('secondaryOccupation', DefinedListDetail, true)
  secondaryOccupation: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('secondaryBusinessType', DefinedListDetail, true)
  secondaryBusinessType: DefinedListDetail = new DefinedListDetail()

  @JsonProperty('address', Address)
  address: Address = new Address()

  @JsonProperty('prn', Number)
  prn: number | null = null
}
