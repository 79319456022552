import { JsonObject, JsonProperty } from 'json2typescript'
import { DefinedListDetail } from '../DefinedList'
import Model from '../Model'
import DateConverter from '@/converters/DateConverter'

@JsonObject('PropertyClaim')
export default class PropertyClaim extends Model {
  @JsonProperty('claimDate', DateConverter)
    claimDate: Date

  @JsonProperty('value', Number)
    value: number

  @JsonProperty('happenedAtProperty', Boolean)
    happenedAtProperty: boolean

  @JsonProperty('claimType', DefinedListDetail)
    claimType: DefinedListDetail

  @JsonProperty('claimCause', DefinedListDetail)
    claimCause: DefinedListDetail

  @JsonProperty('claimStatus', DefinedListDetail)
    claimStatus: DefinedListDetail

  @JsonProperty('prn', Number)
    prn: number | null

  constructor(
        id = 0,
        claimDate = new Date(),
        value = 0,
        happenedAtProperty = false,
        claimType = new DefinedListDetail(),
        claimCause = new DefinedListDetail(),
        claimStatus = new DefinedListDetail(),
        prn = null,
  ) {
    super(id)
    this.claimDate = claimDate
    this.value = value
    this.happenedAtProperty = happenedAtProperty
    this.claimType = claimType
    this.claimCause = claimCause
    this.claimStatus = claimStatus
    this.prn = prn
  }
}
