import { createClient } from '@ignite-systems/auth-service-api'
import useAuthServiceStore from './stores/auth-service-store'

export function getAuthServiceConfig() {
  const authServiceStore = useAuthServiceStore()
  const client = createClient(new URL('/api/trpc', window.injectedEnv.FRONTEND_AUTH_SERVICE_URL).toString())
  return {
    enabled: window.injectedEnv.FRONTEND_AUTH_SERVICE_ENABLED === 'true',
    endpoint: window.injectedEnv.FRONTEND_AUTH_SERVICE_URL,
    getAuthProspectToken: () => client.auth.getAuthProspectToken.query(),
    exchangeAuthProspectToken: (token: string) => client.auth.exchangeAuthProspectToken.query({ token }),
    getAggAuth: (guid: string) => client.auth.getAuthTokenForAgg.query({ guid }),
    getDeepLinkAuth: (guid: string) => client.auth.getAuthTokenForDeepLink.query({ guid }),
    setToken: (token: string, type: Parameters<typeof authServiceStore.setToken>['1']) => {
      authServiceStore.setToken(token, type)
    },
    clearToken: () => {
      authServiceStore.clearToken()
    },
    type: authServiceStore.type,
    token: authServiceStore.token,
    authHeader: authServiceStore.token ? `Bearer ${authServiceStore.token}` : undefined,
  }
}
